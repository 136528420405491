export const light = {
  label: "Default",
  key: "default",
  borderWidth: 1,
  borderRadius: 4,
  padding: 12,
  shadowRadius: 6,
  shadowOpacity: 0.15,
  margin: 10,
  fontSize: {
    heading1: 28,
    heading2: 22,
    value: 18,
    text: 16,
    textSecondary: 13,
  },
  colors: {
    primary: "#eee5ff",
    accent: "#ff375f",
    text: "#000",
    green: "#7de043",
    red: "#cf2c28",
    textSecondary: "#666",
    textInverse: "#333",
    border: "rgba(0,0,0,.3)",
    background: "#fff",
    surface: "#eaeaea",
    shadow: "#000",
  },
}

export const dark = {
  ...light,
  shadowOpacity: 0.33,
  colors: {
    primary: "#eee5ff",
    accent: "#AEE1CD",
    text: "#fff",
    green: "#7de043",
    red: "#e84a61",
    textSecondary: "#808895",
    textInverse: "#000",
    border: "rgba(255,255,255,.3)",
    background: "#1e2128",
    surface: "#343841",
    shadow: "rgba(0,0,0,.2)",
  },
}
