import React from "react"
import Feather from "feather-icons-react"

const Icon = ({ icon, ...props }) => {
  return (
    <Feather
      icon={icon}
      aria-label={`${icon}`}
      width={24}
      strokeWidth={1.5}
      color="inherit"
      {...props}
    />
  )
}

export default Icon
