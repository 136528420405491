import React from "react"
import styled from "styled-components"
import Me from "./icons/Me"

const Container = styled.div`
  display: flex;
  flex-shrink: 0;
  width: ${p => p.size}px;
  height: ${p => p.size}px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background-color: #eaeaea;
  /* border: ${p => p.theme.borderWidth}px solid ${p =>
  p.theme.colors.border}; */
`

const Avatar = ({ size }) => {
  const iconSize = Math.floor(size * 0.75)
  return (
    <Container size={size} aria-label="Home Avatar">
      <Me width={iconSize} color={"#000"} height={iconSize} />
    </Container>
  )
}

Avatar.defaultProps = {
  size: 80,
}

export default Avatar
