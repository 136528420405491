import React, { useContext } from "react"
import { ThemeProvider } from "styled-components"
import { useLocalStorage } from "../utils/hooks"
import * as themes from "./themes"

const UIThemeContext = React.createContext()

export const useUITheme = () => {
  return useContext(UIThemeContext)
}

export const UIThemeContextProvider = ({ children }) => {
  const [themeKey, setThemeKey] = useLocalStorage("theme", "light")
  const toggleTheme = () => {
    setThemeKey(themeKey === "light" ? "dark" : "light")
  }
  const theme = { ...themes[themeKey], key: themeKey }
  return (
    <UIThemeContext.Provider value={{ theme, themeKey, toggleTheme }}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </UIThemeContext.Provider>
  )
}
