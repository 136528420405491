import "./layout.css"
import "./theme.css"

import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { UIThemeContextProvider } from "../ui/theme"
import Avatar from "./Avatar"
import ThemeSwitch from "./ThemeSwitch"
import Icon from "./Icon"
import SEO from "./seo"

const Wrap = styled.div`
  display: flex;
  width: 100%;
  min-height: 100vh;
  color: ${p => p.theme.colors.text};
  background-color: ${p => p.theme.colors.background};
  overflow: hidden;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 800px;
  margin: 0px auto;
  padding: ${p => p.theme.padding}px;

  main {
    padding: ${p => p.theme.padding}px;
    flex-grow: 1;
    a {
      color: ${p => p.theme.colors.text};
      transition: color 0.2s;
      &:hover {
        color: ${p => p.theme.colors.accent};
      }
    }
  }

  @media (max-width: 940px) {
    padding-top: 60px;
  }
`

const Footer = styled.footer`
  display: flex;
  font-family: "Inter", system-ui;
  justify-content: center;
  align-items: center;
  > a {
    text-decoration: none;
    margin-left: ${p => p.theme.margin}px;
    display: flex;
    align-items: center;
    svg {
      margin-right: ${p => p.theme.margin / 2}px;
    }
  }
`

const BackLink = styled(Link)`
  display: flex;
  align-items: center;
  position: absolute;
  left: ${p => p.theme.padding}px;
  top: ${p => p.theme.padding}px;
`

const Layout = ({ children, path }) => {
  return (
    <UIThemeContextProvider>
      <Wrap>
        <SEO title="Mark Johnson: Designer, Developer, Teacher" />{" "}
        <Container>
          {/* <Menu key="menu" /> */}
          {path ? (
            <BackLink to="/">
              <Icon icon="chevron-left" />
              <Avatar size={40} />
            </BackLink>
          ) : null}
          <ThemeSwitch />
          <main>{children}</main>
          <Footer>
            &copy; {new Date().getFullYear()}
            <a href="/rss.xml">
              <Icon icon="rss" />
              RSS
            </a>
            <a href="https://buttondown.email/wmdmark">
              <Icon icon="mail" />
              Newsletter
            </a>
          </Footer>
        </Container>
      </Wrap>
    </UIThemeContextProvider>
  )
}

export default Layout
