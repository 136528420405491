import React from "react"
import styled from "styled-components"
import { useUITheme } from "../ui/theme"
import Icon from "./Icon"

const Switch = styled.div`
  position: absolute;
  top: ${p => p.theme.padding}px;
  right: ${p => p.theme.padding}px;
  color: ${p => p.theme.colors.text}px;
  cursor: pointer;
  &:hover {
    color: ${p => p.theme.colors.accent}px;
  }
`

const ThemeSwitch = () => {
  const { themeKey, toggleTheme } = useUITheme()
  return (
    <Switch onClick={toggleTheme}>
      <Icon icon={themeKey === "dark" ? "sun" : "moon"} />
    </Switch>
  )
}

export default ThemeSwitch
